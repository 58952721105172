import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonial from "../../shared/Static/Testimonial";
import "./style.scss";

const slides = testimonial?.map((element, index) => {
  return <div key={index}>
    <div className=''>
      <div className='d-flex justify-content-center'>
        <img src={element.headerImg} alt="" className='carouselImg ' />
      </div>
      <div className="text-center carouselPara">
        {element.content}
        <p className='authorDetails pt-4' >{element.authorName} <br /> {element.authorSignature}</p>
      </div>
    </div>

  </div>
});

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div className=" carouselStyle">

        <Slider {...settings}>
          {slides}
        </Slider>
      </div>
    );
  }
}