import React, { useState,useEffect } from "react";
import "./style.scss";
import Mail from "../../assets/images/MailIcon.png";
import Mark from "../../assets/images/LocationPoint.png";

function Footer({ currLink }) {
  const [activeLink, setActiveLink] = useState("Home");

  useEffect(() => {
    if (currLink === 'resource') {
      setActiveLink('resource');
    }
    const sections = document.querySelectorAll("section[id]");

    window.addEventListener("scroll", navHighlighter);

    function navHighlighter() {
      
      let scrollY = window.pageYOffset;

      sections.forEach((current) => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 50;

        let sectionId = current.getAttribute("id");

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          document
            .querySelector(".navigation a[href*=" + sectionId + "]")
            .classList.add("active");
        } else {
          document
            .querySelector(".navigation a[href*=" + sectionId + "]")
            .classList.remove("active");
        }
      });
    }

    
  }, []);

  return (
    <footer className="mt-3 ">
      <div className="footBg text-white ">
        <div className=" d-flex footer-width justify-content-between ">
          <div className="d-flex col-12 col-md-6 footBg justify-content-evenly mar-24" >
            <div className="compoItems">
              <a
                className={`nav-link ${activeLink === "home" ? "active" : ""}`}
                href="#home"
                onClick={() => {
                  setActiveLink("home");
                }}
              >
                Home
              </a>
            </div>
            <div className="compoItems">
              <a
                className={`nav-link ${activeLink === "aboutUs" ? "active" : ""
                  }`}
                href="#aboutUs"
                onClick={() => {
                  setActiveLink("aboutUs");
                }}
              >
                About Us
              </a>
            </div>

            <div className="compoItems">
              <a
                className={`nav-link ${activeLink === "jobs" ? "active" : ""}`}
                href="#jobs"
                onClick={() => {
                  setActiveLink("jobs");
                }}
              >
                Jobs
              </a>
            </div>

            <div className="compoItems">
              <a
                className={`nav-link ${activeLink === "resource" ? "active" : ""
                  }`}
                href="#resource"
                onClick={() => {
                  setActiveLink("resource");
                }}
              >
                Resources
              </a>
            </div>

          </div>
          <div className="col-12 col-md-3  bgGrey">
            <p className="mail "><img src={`${Mark}`} alt="" />&nbsp;Chennai &nbsp; Bangalore &nbsp; Delaware</p>
          </div>
          <div className="col-12 col-md-3  bgOrange overLap">
          <p className="mail "><img src={`${Mail}`} alt="" style={{height:"12px",width:"18px"}}/> Email us at{" "}<span><a href="mailto:info@saaconsulting.co.in" className="text-white roman_Helvetica fe-light" style={{ textDecoration: "underline !important" }}>info@saaconsulting.co.in</a> </span></p>
          </div>

        </div>

      </div>

      <div className="">

        <div className="footerText  py-2 px-3  text-center">
          <div className="alignmentClass ">Copyright @ 2022 Saaki, Argus & Averil Consulting. All rights reserved.</div>
          <div className="alignmentClass ">
            Privacy Policy</div>
        </div>

      </div>
    </footer>
  );
}

export default Footer;
