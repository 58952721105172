import React from "react";
import "./style.scss";
import HowWeDo from "../../assets/images/HowWeDoItFinal.png";

function HowWeDoIt() {
  return (
    <div className="howWeDo">

      <div className="row align-items-center bg-grey pt-5 pb-4">
        <h1 className="roman_Helvetica text-center pb-5">How{" "}<span className="bold_Helvetica">We Do It</span></h1>
        <div className="col-12 col-md-6 text-center">
          <img src={`${HowWeDo}`} alt="" />
        </div>
        <div className="pt-5 pb-5 col-12 col-md-6 spacing">
          <h2 className="head-size centerText">
            Perfecting the science of
            <br />
            <span className="science-heading-text fw-bold"> leadership hiring</span>
          </h2>
          <p className="romanHelvetica-font centerText desc-size mt-3">
            As a trusted and experienced executive search and placements
            partner, we have perfected the science of leadership hiring. Our
            unique methodologies-
            <br />
            <span className="text-orange bold_Helvetica">Need Fit Analysis</span> and{" "}
            <span className="text-orange bold_Helvetica">The MATCHbox®{" "}</span>map the right
            candidate on to the right position with proven precision.{" "}
          </p>
        </div>
      </div>
      <div> 
        <div className="pt-5 mb-3">
          <h4 className="romanHelvetica-font method-head">Need-Fit Analysis</h4>
        </div>
        <div className="mb-4 ">
          <p className="romanHelvetica-font method-desc">
            This strategy-based analytical tool enables us to probe, question,
            revisit, and freshly articulate talent requirements if need be. By
            engaging in an open-minded discussion with clients, we jointly
            arrive at precise and effective solutions. At the end of the
            collaborative Need-Fit Analysis brainstorming, clients and
            candidates make hiring and joining decisions with greater clarity.
            We understand that one-size cannot fit all and using our insights,
            deploy the appropriate analysis depending on the requirement. This
            crucial process sharpens the process and result.
          </p>
        </div>
        <div >
          <div className="text-center">
            <p className="romanHelvetica-font method-desc">Our Need-Fit Analysis spans{" "}<span className="text-orange bold_Helvetica">four critical aspects:</span></p>
          </div>

          <div className="row text-center px-3 justify-content-center mb-3 pt-3 cardDivHeight"  >

            <div className="d-flex jump mb-3 justify-content-center place-items-center col-12 col-md-6 col-lg-2">
              <div className="boxContext"></div>
            </div>

            <div className="d-flex jump mb-3 justify-content-center place-items-center col-12 col-md-6 col-lg-2">
              <div className="boxProfile">
              </div>
            </div>

            <div className="d-flex jump mb-3 justify-content-center place-items-center col-12 col-md-6 col-lg-2">
              <div className="boxWork">
                
              </div>
            </div>

            <div className="d-flex jump mb-3 justify-content-center place-items-center col-12 col-md-6 col-lg-2">
              <div className="boxCost">
                
                
              </div>
            </div>
            
          </div>
        </div>
      </div>

      <div >
        <div className="mt-5 mb-3 ">
          <h4 className="romanHelvetica-font method-head">The MATCHbox</h4>
        </div>
        <div className=" mb-4">
          <p className="romanHelvetica-font method-desc">
            The MATCHbox ® is a personality-based archetype that progresses the
            Need-Fit analysis by enabling us to slot requirements as well as
            candidates into appropriate personas and arrive at the most suitable
            match. Our proprietary weighted scoring helps us to zero-in on the
            best fit within the MATCHbox ®. We have arrived at four broad
            personas that we work with. Should there be a need to create a
            unique persona that works in a particular context, we methodically
            do so.
          </p>
        </div>
        <div className="">
          <div className="text-center">
            <p className="romanHelvetica-font method-desc">
              The <span className="text-orange bold_Helvetica">four personas</span> that can be applied to any position across the
              organisation including leadership positions are:{" "}
            </p>
          </div>

          <div className="row text-center px-3 justify-content-center mt-2 mb-3 pt-3 cardDivHeight">
          <div className="d-flex jump mb-3 justify-content-center place-items-center col-12 col-md-6 col-lg-2">
              <div className="boxVisionary">
                
              </div>
            </div>
            
            <div className="d-flex jump mb-3 justify-content-center place-items-center col-12 col-md-6 col-lg-2">
              <div className="boxIntegrator">
                
              </div>
            </div>
            
            <div className="d-flex jump mb-3 justify-content-center place-items-center col-12 col-md-6 col-lg-2">
              <div className="boxSpecialist">
                
              </div>
            </div>
            
            <div className="d-flex jump mb-3 justify-content-center place-items-center col-12 col-md-6 col-lg-2">
              <div className="boxPioneer">
                
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowWeDoIt;
