import React from 'react';
import NavBar from "../nav";
import Footer from "../footer";
import AboutUs from "../aboutUs";
import Header from "../header";
import Jobs from '../jobs';
import Resources from '../resources';

function HomeLayout() {
  return (
    <div>
      <NavBar />
      <section id="home"><Header/></section>
      <section id="aboutUs"><AboutUs/></section>
      <section id="jobs"><Jobs/></section>
      <section id="resource"><Resources/></section>
      <section><Footer/></section>
    </div>
  )
}

export default HomeLayout