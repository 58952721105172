import React from 'react';
import FooterComponent from "../footer/index";
import { useLocation } from "react-router-dom";
import "./Blog6_Style.scss";
import BlogSlick from "./BlogSlick";
import BlogAI from "../../assets/images/AibackgroundFinalPic.png";
import NavBar from '../nav';

function Blog6() {
    const location = useLocation();
    const locName = location.pathname.split('/')[1];

    return (
        <div>
            <NavBar currLink="resource" />
            <div className="container text-start mt-3 pt-5">
                <div className='div-with-heading'>
                    <h2 className='blogTwoHeading pb-4'>Humanoid Recruiters</h2>
                    <p className='roman_Helvetica'>By Staff Editor</p>
                    <div className='pt-4 '>
                    <p className='italic-Helvetica blogPara orangeLine quoteColor'>“Influencers move aside, it is time for AI interviewers” <br /></p>
                </div>
                </div>

                <div className='div-with-text'>

                    <div>
                        <p className='blogPara light_Helvetica text-start line-height-30'>
                        Humanoid Recruiters Lu do Magalu, Lil Miquela, Any Malu and Kyra are all famous people, rephrasing, they are famous but not people! With huge followings on social media across YouTube, Instagram and Facebook, these are virtual AI influencers from Brazil to Canada, and of course India (Kyra). Having come into existence just in the last couple of years these AI Influencers have all been grabbed by global brands to endorse, been seen in fashion weeks and create content that is primarily around fashion and lifestyle. AI influencers, also called virtual influencers are basically virtual personalities who have been built using computer graphics and machine learning algorithms by either the brands or media agencies. As AI technology continues to evolve, it's likely that AI influencers will become more sophisticated, potentially blurring the lines between human and artificial interactions even further. <br /> <br />

                        Well, this post is not about AI influencers and their use in marketing. With them getting more evolved and adopting more and more human like behaviors, are there other use cases; like perhaps creating - AI Interviewers??!! AI in interviews has been gaining traction, primarily in the form of automated interview platforms and tools that are aimed to assist in the hiring process. Resume Screening, chat bot interviews, video interview looking into facial expressions, analyzing response patterns and arriving at suitability for the role and the organization itself. While these tools have helped organizations save time; they have yet not completely eliminated the face-to-face round which necessitates a “real” human interviewer to attest the candidate. <br /> <br />
                        </p>
                        <div className='d-flex justify-content-center'>
                            <img src={`${BlogAI}`} alt="" className='blogImageWidth pb-5' />
                        </div>
                        <div>
                            <div className='blogPara light_Helvetica text-start line-height-30'>
                                <h3 className='boldHelvetica-font coachingHeading aiHeading'>Creating an AI persona for interviewers within your company with the values of your brand involves a careful and strategic process. Here are some things you may want to consider when planning to build AI interviewers:<br /><br /></h3>

                                You could build multiple AI interviewers by having an overarching persona that will act as the face of your AI interactions, embodying your brand's identity, values, and tone., yet tweak these to incorporate individual traits and quirks to make them more believable. <br /> <br />

                            </div>
                            <div className='blogPara light_Helvetica text-start line-height-30'>
                                <ol>
                                    <li>
                                    <span className='medium_Helvetica grayColor'>Define Your Brand Values - </span> 
                                    Start by clearly identifying and articulating your brand's core values. What does your company stand for? What are the principles that guide your actions and decisions? These values will form the foundation of your AI persona. You could build multiple AI interviewers by having an overarching persona that will act as the face of your AI interactions, embodying your brand's identity, values, and tone., yet tweak these to incorporate individual traits and quirks to make them more believable. 
                                    </li>
                                    <br />

                                    <li>
                                    <span className='medium_Helvetica grayColor'>Understand Your Target Audience “the candidate” - </span> 
                                    What are the demographic preferences, needs and expectations of the candidate community during an interview. This information will help you tailor your AI persona's traits and communication style to resonate with your audience.
                                    </li>
                                    <br />

                                    <li>
                                    <span className='medium_Helvetica grayColor'>Develop a Persona Profile -</span> 
                                    Create a detailed persona profile for your AI. This should include attributes such as name, age, gender (if applicable), hobbies, interests, and background including education and expertise in any particular area, be it technology, sales, finance or HR. Analyze your current interviewers for various roles and then simply think of your AI as a virtual representative of them. 
                                    </li>
                                    <br />

                                    <li>
                                    <span className='medium_Helvetica grayColor'>Consistent yet unique -</span> 
                                    Your AI persona's tone and voice should reflect your brand's personality. If your brand is professional and serious, the AI should communicate in a formal manner. If your brand is more casual and friendly, the AI can adopt a conversational and approachable tone. Simultaneously build unique attributes for each type of interviewer, say an HR interviewer persona and the questioning style would be vastly different from a technology role interviewer. 
                                    </li>
                                    <br />

                                    <li>
                                    <span className='medium_Helvetica grayColor'>Context and Niche - </span> 
                                    For AI interviewers to become believable, the niche or industry in which your business operates is an important training aspect that needs to be considered. For instance, an AI interviewer to sound credible would be expected to provide information not only about your company but overall industry you are operating within. At the same time be able to identify similar contexts that the candidate comes from. 
                                    </li>
                                    <br />

                                    <li>
                                    <span className='medium_Helvetica grayColor'>Train the AI Model - </span> 
                                    The style of questioning and questions itself can be trained for individual functional roles by using multiple language models that are available. This might include providing specific training data on questions that are relevant to your company and even examples of expected responses.
                                    </li>
                                    <br />

                                    <li>
                                    <span className='medium_Helvetica grayColor'>Test and Refine - </span> 
                                    Conduct thorough testing to ensure that the AI persona's responses are consistent with expectations during an interview and the outcomes. Solicit feedback from testers and users to identify any areas for improvement or misalignment.
                                    </li>
                                    <br />

                                    <li>
                                    <span className='medium_Helvetica grayColor'>Address Ethical Considerations - </span> 
                                    AI personas should be designed ethically, avoiding bias, offensive language or discriminatory behavior. Ensure that your AI Interviewer respects user privacy and maintains appropriate boundaries.
                                    </li>
                                    <br />

                                    <li>
                                    <span className='medium_Helvetica grayColor'>Regular monitoring for continuous evolvement - </span> 
                                    As your brand grows and changes and your business differentiates, your AI persona should evolve accordingly. Regularly monitor the AI's interactions and gather feedback. Use this information to make necessary updates and refinements to the AI persona over time. Assess whether the persona remains aligned with your brand's values, requirements of the business and make adjustments as needed. 
                                    </li>
                                    <br />

                                </ol>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <BlogSlick name={locName}/>
            <FooterComponent />
        </div>
    );
}

export default Blog6;
