import React, { useState, useEffect } from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";
import SaaLogo from "../../assets/images/SaaLogo.png";
import linkedInIcon from '../../assets/images/LinkedInIcon.png';
import fbIcon from '../../assets/images/FacebookIcon.png';

function NavBar({ currLink }) {

  const sections = document.querySelectorAll("section[id]");
  const location = useLocation();
  const locName = location.hash.split('#')[1];
  const [activeLink, setActiveLink] = useState(locName ? locName : 'home');
  

  useEffect(() => {
    // console.log("useEffect")
    if (currLink === 'resource') {
      setActiveLink('resource');
    }
    else {
      setActiveLink(activeLink);
    

      const b = document.getElementsByClassName('active')
      b[0].click();

   
    }

    window.addEventListener("scroll", navHighlighter);

  }, [activeLink])

  useEffect(()=>{
    // closeNav();
    // console.log("First")
    
    if (window.innerWidth <= 426) {
        
  
      setTimeout(()=>{
        closeNav();
      }, 500)
    
    }
    closeNav();
  }, [])

  function navHighlighter() {
    let scrollY = window.pageYOffset;
    sections.forEach((current) => {
      const sectionHeight = current.offsetHeight;
      const sectionTop = current.offsetTop - 50;
      let sectionId = current.getAttribute("id");
      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        document
          .querySelector(".navigation a[href*=" + sectionId + "]")
          .classList.add("active");
      } else {
        document
          .querySelector(".navigation a[href*=" + sectionId + "]")
          .classList.remove("active");
      }
    });
  }

  const closeNav = () => {

    if (window.innerWidth <= 426) {
      // console.log("--><")
      document.getElementById('closebtn').click();
    }
  }

  return (
    <div className="p-2 d-flex align-items-center mb-3">
      <nav className="px-3 navbar fixed-top navbar-expand-lg navbar-light bg-light navigation">
        <a className="navbar-brand" href="/#">
          <img src={`${SaaLogo}`} alt="" style={{ width: "85px", height: "50px" }} />
        </a>
        <button
          id="closebtn"
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto text-center">
            <li className="nav-item fw-bold nav-components ms-3 me-3">
              <a
                className={`nav-link ${activeLink === "home" ? "active" : ""}`}

                href="#home"
                onClick={() => {
                  
                  setActiveLink("home");
                  closeNav();
                }}
              >
                Home
              </a>
            </li>

            <li className="nav-item fw-bold nav-components ms-3 me-3">
              <a
                className={`nav-link ${activeLink === "aboutUs" ? "active" : ""
                  }`}

                href="#aboutUs"
                onClick={() => {
                

                  setActiveLink("aboutUs");
                  closeNav();
                }}
              >
                About Us
              </a>
            </li>

            <li className="nav-item fw-bold nav-components ms-3 me-3">
              <a
                className={`nav-link ${activeLink === "jobs" ? "active" : ""}`}
                href="#jobs"
                onClick={() => {
                 
                  setActiveLink("jobs");
                  closeNav();
                }}
              >
                Jobs
              </a>
            </li>
            <li className="nav-item fw-bold nav-components ms-3 me-3">
              <a
                className={`nav-link ${activeLink === "resource" ? "active" : ""
                  }`}
                href="#resource"
                onClick={() => {
                  
                  setActiveLink("resource");
                  closeNav();
                }}
              >
                Resources
              </a>
            </li>

          </ul>

          <div className="d-flex justify-content-center" style={{ paddingTop: "16px", paddingBottom: "16px" }}>
            <a href="https://www.facebook.com/saakiconsulting" target="_blank">
              <img src={`${fbIcon}`} alt="" className="iconSizeMobile" style={{ marginRight: "5px" }} />
            </a>
            <a href="https://www.linkedin.com/company/saaki-and-argus/" target="_blank">
              <img src={`${linkedInIcon}`} alt="" className="iconSizeMobile" style={{ marginLeft: "5px" }} />
            </a>

          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
