
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import './App.scss';


import Blog1 from './components/blogs/blog1';
import Blog2 from "./components/blogs/Blog2";
import Blog3 from "./components/blogs/Blog3";
import Blog4 from "./components/blogs/Blog4";
import Blog5 from "./components/blogs/Blog5";
import Blog6 from './components/blogs/Blog6';
import ViewAll from './components/resources/ViewAll';
import HomeLayout from './components/layout';
function App() {
  return (



    <Router>

      <Routes>
        <Route exact path='/' element={<HomeLayout />}></Route>
        <Route exact path='/blog1' element={<Blog1 />}></Route>
        <Route exact path="/blog2" element={<Blog2 />}></Route>
        <Route exact path="/blog3" element={<Blog3 />}></Route>
        <Route exact path="/blog4" element={<Blog4 />}></Route>
        <Route exact path="/blog5" element={<Blog5 />}></Route>
        <Route exact path="/blog6" element={<Blog6 />}></Route>
        <Route exact path="/viewall" element={<ViewAll />}></Route>
      </Routes>
    </Router >

  );
}

export default App;
