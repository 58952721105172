import React, { useState } from "react";
import "./style.scss";
import Sathya from "../../assets/images/Sathya.png";
import Sunil from "../../assets/images/Sunil.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/fontawesome-free-solid'

function Team() {

  const [sathyaMore, setSathyaMore] = useState(false);

  const sathyaContent = (
    <div>
      <p className="px-4 description">
        Over the last two decades she has helped transform careers
        of innumerable leaders by managing their transition into
        growth. Her expertise working with Technology and Digital
        Leaders has been built over time servicing customers in
        the IT Industry. The recent years have witnessed dynamic
        changes in the Recruitment industry, from the old school
        of Head hunting through cold calling to the new age social
        media sourcing and hiring Sathya has seen from inside,
        this evolution of innovative strategies in the Executive
        Search Industry.
        <br /><br />
        Sathya, completed her Masters in Human Resources
        Management from Madras University, started off her career
        with Larsen and Tubro but soon joined the executive search
        Industry and founded the firm.
        <br /><br />
        She lives in Chennai and is an amateur podcaster
      </p>
    </div>
  );
  const sunilContent = (
    <div>
      <p className="px-4 description">
        

        With this varied industry exposure he provides knowhow
        required for us to deliver to varied customers. Having
        worked with large manufacturing and services
        organizations, Sunil brings new meaning to the word
        'well-rounded experience' since he has also worked for
        Indian MNCs and Global MNCs with equal ease and
        distinction.
        <br /><br />
        He has successfully lead numerous search assignments
        across HR, Sales and ECom business verticals. His customer
        oriented approach and people skills has enabled him to be
        trusted advisor for various HR Heads.
      </p>
    </div>
  );

  const sathyaOption = sathyaMore ? (
    <FontAwesomeIcon className="arrows" icon={faAngleUp} />
  ) : (
    <FontAwesomeIcon className="arrows" icon={faAngleDown} />
  );
  const sunilOption = sathyaMore ? (
    <FontAwesomeIcon className="arrows" icon={faAngleUp} />
  ) : (
    <FontAwesomeIcon className="arrows" icon={faAngleDown} />
  );

  return (
    <div id="team" className=" pb-5 conatiner">

      <div className="text-center pt-5 ">
        <h3 className="romanHelvetica-font leadership-text">Our{" "}<span className="fw-bold">Leadership</span></h3>
      </div>


      <div>
        <div className="customer-feedback">
          <div className=" text-center">
            <div className="row ">
              <div className="col-12">
                <div className="owl-carousel feedback-slider d-flex justify-content-evenly  row">
                  <div className="feedback-slider-item col-sm-4 paddin">
                    <img
                      src={`${Sathya}`}
                      className="center-block rounded-circle img-height img-width img-position"
                      alt="Customer Feedback"
                    />
                    <h3 className="customer-name mt-3">Sathya Krishnan</h3>
                    <p className="designation">
                      Founder and Partner
                      <br />

                    </p>
                    <p className="px-4 description">
                      Sathya has 25 years in executive and leadership search
                      experience working largely with Technology industry.
                    </p>
                    <span

                      onClick={() => {
                        setSathyaMore(!sathyaMore);
                      }}
                    >
                      {sathyaMore && sathyaContent}
                      <h1 className="readOption">{sathyaOption}</h1>
                    </span>


                  </div>

                  <div className="feedback-slider-item col-sm-4 paddin">
                    <img
                      src={`${Sunil}`}
                      className="center-block rounded-circle img-height img-width img-position"
                      alt="Customer Feedback"
                    />
                    <h3 className="customer-name mt-3">Sunil K P</h3>
                    <p className="designation">
                      Founder and Partner
                      <br />

                    </p>
                    <p className="px-4 description">
                      Sunil has close to two decades of experience working in
                      various Senior Leadership roles providing strategic HR directions across different industries like Manufacturing,
        Telecom, IT and ITES.
                    </p>
                    <div>
                      <span

                        onClick={() => {
                          setSathyaMore(!sathyaMore);
                        }}
                      >
                        {sathyaMore && sunilContent}
                        <h1 className="readOption">{sunilOption}</h1>
                      </span>
                      
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
