import React from "react";
import "./style.scss";
import BlogDisagree from "../../assets/images/DisagreeResource.png";
import BlogRecession from "../../assets/images/RecessionResource.png";
import BlogCoach from "../../assets/images/CoachResource.png";
import BlogAI from "../../assets/images/AibackgroundResource.png";
// import BlogEvents from "../../assets/images/EventsResource.png"
import OrangeArrow from "../../assets/images/RightArrow.png";
import BlogSlick from "../blogs/BlogSlick";


function Resources() {

  return (
    <div className="pt-5">
      <div id="resource" className=" container">

        <div className="desktopNone">
          <BlogSlick name="" />
          <div className="d-flex justify-content-center pt-3 pb-3">
            <button className="btn button-color">
              {" "}
              <a
                href="/viewall"
                target="_blank"
                rel="noreferrer"
                className="text-white text-decoration-none txt-size"
              >
                View All{" "}
              </a>
            </button>
          </div>
        </div>

        <div className="displayOption ">
          <div className="text-center pt-5">
            <h3 className="blogs-head">
              Resources
            </h3>
          </div>

          <div className="row text-center align-items-center px-3">
            <div className="pt-5 pb-5 col-12 col-md-6 paddingCards" >
              <div className="shadow whiteBg dimensionsRight" style={{ marginBottom: "3rem" }} >
                <a href="/blog6"
                  target="_blank"
                  rel="noreferrer" style={{ textDecoration: "none" }}>

                  <img
                    src={`${BlogAI}`} 
                    // need to change
                    alt=".."
                    className=""
                  /></a>
                  <h4 className="resource-head  boldHelvetica-font text-black text-start ms-3 pt-2" >Humanoid Recruiters</h4>
                  <div className="buttonDecor">

                    {" "}
                    <a
                      href="/blog6"
                      target="_blank"
                      rel="noreferrer"
                      className=" text-decoration-none btn-txt romanHelvetica-font"
                    >
                      <button className='btn' style={{ marginRight: "30px", border: "none" }}>Read More{" "}<img src={`${OrangeArrow}`} alt="" style={{ marginLeft: "8px" }} /></button>
                    </a>


                  </div>
                
              </div>
              <div className="shadow whiteBg dimensionsRight" >
                <a href="/blog1"
                  target="_blank"
                  rel="noreferrer" style={{ textDecoration: "none" }}>
                  <img
                    src={`${BlogRecession}`}
                    alt=".."
                    className=""
                  /></a>
                  <h4 className="resource-head text-black boldHelvetica-font text-start ms-3 pt-2">Why waste a good recession?</h4>
                  <div className="buttonDecor">

                    {" "}
                    <a
                      href="/blog1"
                      target="_blank"
                      rel="noreferrer"
                      className=" text-decoration-none btn-txt romanHelvetica-font"
                    >
                      <button className='btn' style={{ marginRight: "30px", border: "none" }}>Read More{" "}<img src={`${OrangeArrow}`} alt="" style={{ marginLeft: "8px" }} /></button>
                    </a>

                  </div>
                
              </div>
            </div>
            <div className="pt-5 pb-5 col-12 col-md-6 paddingCards" >
              <div className="shadow whiteBg dimensionsRight" style={{ marginBottom: "3rem" }}>
                <a href="/blog3"
                  target="_blank"
                  rel="noreferrer" style={{ textDecoration: "none" }}>

                  <img
                    src={`${BlogDisagree}`}
                    alt=".."
                    className=""
                  /></a>
                  <h4 className="resource-head  boldHelvetica-font text-black text-start ms-3 pt-2" >Disagreeing is good. Doing it respectfully is better.</h4>
                  <div className="buttonDecor">

                    {" "}
                    <a
                      href="/blog3"
                      target="_blank"
                      rel="noreferrer"
                      className=" text-decoration-none btn-txt romanHelvetica-font"
                    >
                      <button className='btn' style={{ marginRight: "30px", border: "none" }}>Read More{" "}<img src={`${OrangeArrow}`} alt="" style={{ marginLeft: "8px" }} /></button>
                    </a>


                  </div>
                
              </div>
              <div className="shadow whiteBg mt-4 dimensionsRight" >
                <a href="/blog2"
                  target="_blank"
                  rel="noreferrer" style={{ textDecoration: "none" }}>
                  <img src={`${BlogCoach}`} alt=".." className="" /></a>
                  <h4 className="resource-head text-black boldHelvetica-font text-start ms-3 pt-2">
                    Why do you need a transition coach, seriously?!
                  </h4>
                  <div className="buttonDecor">

                    {" "}
                    <a
                      href="/blog2"
                      target="_blank"
                      rel="noreferrer"
                      className=" text-decoration-none btn-txt romanHelvetica-font"
                    >
                      <button className='btn' style={{ marginRight: "30px", border: "none" }}>Read More{" "}<img src={`${OrangeArrow}`} alt="" style={{ marginLeft: "8px" }} /></button>
                    </a>

                  </div>
                
              </div>

              {/* <div className="shadow whiteBg mt-4 dimensionsRight">
                <a href="/blog5"
                  target="_blank"
                  rel="noreferrer" style={{ textDecoration: "none" }}>
                  <img src={`${BlogEvents}`} alt=".." className="" /></a>
                  <h4 className="resource-head text-black boldHelvetica-font text-start ms-3 pt-2">
                    Jobs are journeys, not events!
                  </h4>
                  <div className="buttonDecor">

                    {" "}
                    <a
                      href="/blog5"
                      target="_blank"
                      rel="noreferrer"
                      className=" text-decoration-none btn-txt romanHelvetica-font"
                    >
                      <button className='btn' style={{ marginRight: "30px", border: "none" }}>Read More{" "}<img src={`${OrangeArrow}`} alt="" style={{ marginLeft: "8px" }} /></button>
                    </a>

                  </div>
                
              </div> */}
            </div>

          </div>
          <div className="d-flex justify-content-center pt-1 pb-3">
            <button className="btn button-color">
              {" "}
              <a
                href="/viewall"
                target="_blank"
                rel="noreferrer"
                className="text-white text-decoration-none txt-size"
              >
                View All{" "}
              </a>
            </button>
          </div>
        </div>


      </div>
    </div>
  );
}

export default Resources;
