import React from 'react';
import NavBar from '../nav';
import "./Blog4_Style.scss";
import { useLocation } from "react-router-dom";
import FooterComponent from "../footer/index";
import BlogSlick from "./BlogSlick";
import BlogBirds from "../../assets/images/AdaptiveBlogFinalPic.png";

function Blog4() {
    const location = useLocation();
    const locName = location.pathname.split('/')[1];
    return (
        <div>
            <NavBar currLink="resource" />
            <div className="container mt-5 pt-5">
                
                <h2 className='bold_Helvetica BlogHeading mt-3 mb-3'>Adaptive Leadership in the New Paradigm of Work</h2>
                <p className='roman_Helvetica'>By Staff Editor</p>
                <div className='mt-4 mb-5 orangeLine'>
                    <p className='italic_Helvetica italic-Para quoteColor'>
                        “The illiterate of the 21st century will not be those who cannot read and write, but will be thosewho cannot learn, unlearn and relearn.”
                       <br/><span className='roman_Helvetica'> ~ Alvin Toffler, Future Shock</span>
                    </p>
                </div>
                <div className='line-height-30'>
                    <p className='light_Helvetica DescPara'>
                        As business leaders, we had become comfortable with a world where predictability and control were valued and rewarded. The new normal upset this apple cart. Unexpected events, constant change, inevitable failures and growing lack of control, are all inherent to the new world of work.<br /><br />
                        The Information Economy has given way to the Connection Economy. This tech-fuelled disruptive shift, brought about social technologies that have enabled ubiquitous access to information; levelling the playing field. <br /><br />
                        The season of ‘authoritative leadership,’ where problems are known, the solutions clear and the search for leaders who can get the job done (authoritatively) has passed on. Leadership now passes on to those who can build adaptability and nimbleness into the very DNA of their organisations, while creating authentic relationships with those who engage with their businesses. <br /> <br />
                    </p>
                </div>
                <div>
                    <img src={`${BlogBirds}`} alt="" className='BlogImage pb-3' />
                </div>
                <div className='mt-4 DescPara'>
                    <h3 className='bold_Helvetica BlogHeading'>The DNA of Adaptive Leadership</h3>
                    <p className='pt-3 pb-3 roman_Helvetica line-height-30'>Leaders who possess an adaptive DNA, can be distinguished by the following behaviours:</p>
                    <ul className='line-height-30'>
                        <li className='light_Helvetica pb-3'>
                            <span className='medium_Helvetica grayColor'> Retaining the essential, re-engineering the vulnerable - </span> recognising what is valuable and worth keeping. ‘Testing’ this through intentional team discussions.
                        </li>
                        <li className='light_Helvetica pb-3'>
                        <span className='medium_Helvetica grayColor'>Opening everything to trial and error - </span> Establishing ‘sandboxes’ in which experiments can happen.
                        </li>
                        <li className='light_Helvetica pb-3'>
                        <span className='medium_Helvetica grayColor'> Becoming challenge hunters, rather than reactors - </span> Look for challenge areas, before they become emergencies.
                        </li>
                        <li className='light_Helvetica pb-3'>
                        <span className='medium_Helvetica grayColor'> Responsively improvising anything new - </span> Using experience to creatively respond to changing circumstances.
                        </li>
                        <li className='light_Helvetica pb-3'>
                        <span className='medium_Helvetica grayColor'>Modelling corporate values, rather than virtue signalling - </span> Demonstrating corporate values in action, rather than lip service.
                        </li>
                        <li className='light_Helvetica pb-3'>
                        <span className='medium_Helvetica grayColor'>Believing that people matter more than losses or gain - </span> Hold people tightly with compassion, while making tough decisions.
                        </li>
                        <li className='light_Helvetica pb-3'> 
                        <span className='medium_Helvetica grayColor'>Knowing what to do when no one knows what to do - </span> Engaging all the stakeholders in finding a creative solution, because there’s wisdom in many counsellors.
                        </li>
                        <li className='light_Helvetica pb-3'>
                        <span className='medium_Helvetica grayColor'>Asking the right questions, rather than pursuing the right answers - </span> Answers will change situationally, but asking the right questions will provide adaptive solutions.
                        </li>
                        <li className='light_Helvetica pb-3'>
                        <span className='medium_Helvetica grayColor'>Befriending paradox - </span> Finding ways of holding multiple ‘right’ solutions in sync.
                        </li>
                        <li className='light_Helvetica pb-3'>
                        <span className='medium_Helvetica grayColor'>Embracing diversity - </span> Distinguishing between creating variety and creating a diverse, interacting ecosystem.
                        </li>
                        <li className='light_Helvetica pb-3'>
                        <span className='medium_Helvetica grayColor'>Nurturing dialogue, encouraging collaboration - </span> Realising that the era of ‘authoritative experts’ is history and ideas can come from anywhere.
                        </li>
                        <li className='light_Helvetica pb-3'>
                        <span className='medium_Helvetica grayColor'>Being comfortable with the risky, the uncertain, the messy - </span> As in social life, so in corporate life. What you can’t control, take responsibility for.
                        </li>
                    </ul>
                    <h3 className='bold_Helvetica BlogHeading mt-4'>
                        The future of adaptive leadership – being before doing
                    </h3>
                    <p className='orangeLine italic_Helvetica mt-5 mb-5 quoteColor'>
                        ‘Jazz comes from who you are, where you’ve been, what you’ve done.  If you don’t live it, it won’t come out of your horn.’ <br />
                        <span className='roman_Helvetica'>~ Charlie Parker, Jazz Saxophonist</span>
                    </p>
                    <p className='light_Helvetica line-height-30'>
                        As with jazz, so with leadership. It’s all about who you are in the role. It’s about character, looking inwards and being filled with hope.
                    </p>
                    <p className='light_Helvetica mt-5 line-height-30'>
                        Understanding what’s temporary and what’s transformative calls for leaders to know themselves and their strengths. To see the opportunity in change and not be threatened by it. To walk humbly
                        with their feet firmly planted on the ground, their heads in the clouds and their eyes seeking wisdom and insights from the everyday, the small and the overlooked.
                    </p>
                </div>
                
            </div>
            <BlogSlick name={locName}/>
            <FooterComponent/>
        </div>
    );
}

export default Blog4;
