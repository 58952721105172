import React from "react";
import MobileVideo from "../../assets/images/SAA_mobile_com.mp4";
import DesktopVideo from "../../assets/videos/SAA_Desktop.mp4";
import MobileVideoWebM from "../../assets/videos/SAA_mobile_video.webm";
import DesktopVideoWebM from "../../assets/videos/SAA_DesktopWeb.webm";
import MobileVideoGif from "../../assets/gifs/SAA_Mobile_Compressed.gif";
// import DesktopVideoGif from "../../assets/gifs/SAA_Web_compressed.gif";
import "./style.scss";
import Carousel from '../carousel';
// import MobileVideoCompressed from "../../assets/images/BirdsFull.png";
// import ImageSlider from './imgSlider';

import Slick from '../slick/index';
import LazyLoad from 'react-lazy-load';


function Header() {
  return (
    <div id="home" className="pt-3 ">
      <div>
        <div
          id="carouselVideoExample"
          className="carousel slide carousel-fade "
          data-mdb-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              
              <LazyLoad >

                <video className="desktopView " playsInline autoPlay muted loop 
                // poster={`${DesktopVideoGif}`} 
                preload={'metadata'} >
                  <source
                    src={`${DesktopVideo}`}
                    type="video/mp4"
                  />
                  <source
                    src={`${DesktopVideoWebM}`}
                    type="video/webm"
                  />
                </video>
              </LazyLoad>

              <LazyLoad>
                <video className="mobileView " playsInline autoPlay muted loop poster={`${MobileVideoGif}`} preload={'metadata'}>
                  <source
                    src={`${MobileVideo}`}
                    type="video/mp4"
                  />
                  <source
                    src={`${MobileVideoWebM}`}
                    type="video/webm"
                  />
                </video>
              </LazyLoad>
              {/* <div className="mobileView ">
              <ImageSlider/>
              </div> */}

            </div>
          </div>
        </div>
        
      </div>

      <div className="p-4 paragraph1 text-center">
        <p className=" para-padding">
          <span className="company-name">Saaki Argus & Averil Consulting</span> is a well-established
          name in executive search and leadership solutions which successfully
          partners global organisations in identifying and hiring their future
          leaders. Companies trust us to find their best fit CEOs, CXOs and the
          entire suite of senior management through productive strategic
          collaborations. We always exceed clients' expectations in delivering
          high quality talent - promptly, accurately and with integrity.
        </p>
      </div>


      <Slick />
      <Carousel />
    </div>
  );
}

export default Header;
