import React from 'react';
import BlogDisagree from "../../assets/images/FightViewall.png";
import BlogRecession from "../../assets/images/SittingViewall.png";
import BlogCoach from "../../assets/images/TalkingViewall.png";
import BlogJobs from "../../assets/images/WalkingViewall.png";
import BlogBirds from "../../assets/images/FlyViewall.png";
import BlogAI from "../../assets/images/AibackgroundViewall.png";
import OrangeArrow from "../../assets/images/RightArrow.png";
import NavBar from '../nav';
import FooterComponent from "../footer/index";
import "./viewStyle.scss";

function ViewAll() {
    return (
        <div className=''>
            <NavBar currLink="resource" />
            <div className='container'>
                <div className="card text-start centered" style={{ width: "100%", height: "100%", marginTop: "2rem", border: "none" }} >
                    <div className="row g-0">
                        <div className="col-md-4">
                            <a href='/blog6'><img src={`${BlogAI}`} className="img-fluid imageMargin" style={{ width: "100%" }} alt="..." /></a>

                        </div>
                        <div className="col-md-8 ">
                            <div className="card-body bodyText">
                                <h5 className="card-title blogTitle">Humanoid Recruiters</h5>
                                <p className="card-text dontDisplay blogText">Humanoid Recruiters Lu do Magalu, Lil Miquela, Any Malu and Kyra are all famous people, rephrasing, they are famous but not people! With huge followings on social media across YouTube, Instagram and Facebook, these are virtual AI influencers from Brazil to Canada, and of course India (Kyra). Having come into existence just in last couple of years.</p>
                                <div className="text-end">
                                    <a href='/blog6'>
                                        <button className='btn btnText' style={{ border: "none" }}>Read More{" "}<img src={`${OrangeArrow}`} alt="" style={{ marginLeft: "8px" }} /></button>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="card text-start centered" style={{ width: "100%", height: "100%", border: "none" }} >
                    <div className="reverse row g-0">

                        <div className="col-md-8 ">
                            <div className="card-body bodyText">
                                <h5 className="card-title blogTitle">Disagreeing is good. Doing it respectfully is better.</h5>
                                <p className="card-text dontDisplay blogText">A great illustration of this maxim, is a story told by a gentleman who served on one of Walt Disney’s original advisory boards, when speaking about how Disney responded to disagreement: Walt Disney would occasionally present some unbelievable, extensive dream he was entertaining. Almost without exception, the members of his board would gulp, blink, and stare back at him in disbelief, resisting even the thought of such a thing. But unless every </p>
                                <div className="text-end">
                                    <a href='/blog3'>
                                        <button className='btn btnText' style={{ border: "none" }}>Read More{" "}<img src={`${OrangeArrow}`} alt="" style={{ marginLeft: "8px" }} /></button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <a href='/blog3'><img src={`${BlogDisagree}`} className="img-fluid imageMargin" style={{ width: "100%" }} alt="..." /></a>

                        </div>
                    </div>
                </div>
                <div className="card text-start centered" style={{ width: "100%", height: "100%", border: "none" }} >
                    <div className="row g-0">
                        <div className="col-md-4">
                            <a href='/blog1'><img src={`${BlogRecession}`} className="img-fluid imageMargin" style={{ width: "100%" }} alt="..." /></a>

                        </div>
                        <div className="col-md-8 ">
                            <div className="card-body bodyText">
                                <h5 className="card-title blogTitle">Why waste a good recession?</h5>
                                <p className="card-text dontDisplay blogText">The Federal Reserve has raised interest rates and it’s likely to continue; a move that will increase the borrowing cost for businesses. A significant number of companies have already frozen their IT budgets. The war in Ukraine has pushed up oil costs and consequently gas prices; impacting the cost of living and resulting in inflation.</p>
                                <div className="text-end">
                                    <a href='/blog1'>
                                        <button className='btn btnText' style={{ border: "none" }}>Read More{" "}<img src={`${OrangeArrow}`} alt="" style={{ marginLeft: "8px" }} /></button>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="card text-start centered" style={{ width: "100%", height: "100%", border: "none" }} >
                    <div className="reverse row g-0">

                        <div className="col-md-8 ">
                            <div className="card-body bodyText">
                                <h5 className="card-title blogTitle">Why do you need a transition coach, seriously?! </h5>
                                <p className="card-text dontDisplay blogText">Career change statistics tell us that the average person will change careers 10 to 15 times during their working years. More importantly 30% of the current workforce will change careers or jobs every year (12 months). At this rate of flux, it’s virtually impossible for individuals to know in advance, be prepared for, or cope up with the specificities</p>
                                <div className="text-end">
                                    <a href='/blog2'>
                                        <button className='btn btnText' style={{ border: "none" }}>Read More{" "}<img src={`${OrangeArrow}`} alt="" style={{ marginLeft: "8px" }} /></button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <a href='/blog2'><img src={`${BlogCoach}`} className="img-fluid imageMargin" style={{ width: "100%" }} alt="..." /></a>

                        </div>
                    </div>
                </div>

                <div className="card text-start centered" style={{ width: "100%", height: "100%", border: "none" }} >
                    <div className="row g-0">
                        <div class="col-md-4">
                            <a href='/blog5'><img src={`${BlogJobs}`} className="img-fluid imageMargin" style={{ width: "100%" }} alt="..." /></a>

                        </div>
                        <div className="col-md-8 ">
                            <div className="card-body bodyText">
                                <h5 className="card-title blogTitle">Jobs are journeys, not events!</h5>
                                <p className="card-text dontDisplay blogText">There are many ways to look at this Simon Sinek quote. Here are three stories that broadly illustrate the reality of the career dilemma that thousands of job-seekers face. The conclusions to each story, provides perspectives that job-seekers would do well to consider.</p>
                                <div className="text-end">
                                    <a href='/blog5'>
                                        <button className='btn btnText' style={{ border: "none" }}>Read More{" "}<img src={`${OrangeArrow}`} alt="" style={{ marginLeft: "8px" }} /></button>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="card text-start centered" style={{ width: "100%", height: "100%", border: "none" }} >
                    <div className="reverse row g-0">

                        <div className="col-md-8 ">
                            <div className="card-body bodyText">
                                <h5 className="card-title blogTitle">Adaptive Leadership in the New Paradigm of Work</h5>
                                <p className="card-text dontDisplay blogText">As business leaders, we had become comfortable with a world where predictability and control were valued and rewarded. The new normal upset this apple cart. Unexpected events, constant change, inevitable failures and growing lack of control, are all inherent to the new world of work.
                                </p>
                                <div className="text-end">
                                    <a href='/blog4'>
                                        <button className='btn btnText' style={{ border: "none" }}>Read More{" "}<img src={`${OrangeArrow}`} alt="" style={{ marginLeft: "8px" }} /></button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <a href='/blog4'><img src={`${BlogBirds}`} className="img-fluid imageMargin" style={{ width: "100%" }} alt="..." /></a>

                        </div>
                    </div>
                </div>

            </div>
            <FooterComponent />
        </div>
    );
}

export default ViewAll;
