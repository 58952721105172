import React from 'react';
import NavBar from '../nav';
import { useLocation } from "react-router-dom";
import blog1Img from '../../assets/images/RecessionBlogFinalPic.png'
import "./blog1_style.scss";
import Footer from '../footer';
import BlogSlick from "./BlogSlick";


function Blog1() {
    const location = useLocation();
    const locName = location.pathname.split('/')[1];
    return (
        <div>
            <NavBar currLink="resource" />
            <div className="container mt-3 pt-5">
                <div className='row'>
                    <div className='col'>
                        <div className='paddingClass'>
                            <img src={blog1Img} alt="" className='blogImageWidth ' />
                        </div>
                        <div className='main-div'>


                            <div className='orangeLine mt-3'>
                                <p className='italic_Helvetica italic-font quoteColor'>
                                    “I've heard there’s going to be a recession, I've decided not to participate.”<br></br>
                                    <span className='roman_Helvetica '>~ Walt Disney</span>
                                </p>

                            </div>
                            <div className='light_Helvetica line-height-30 font-20 fw-400 pt-4 font-in-mob'>
                                If you think that a recession is just around the corner, you perhaps haven’t noticed the ripples across the pond of the gigantic US economy. Here’s a drone view:

                                <br></br>
                                <br></br>
                                The Federal Reserve has raised interest rates and it’s likely to continue; a move that will increase the borrowing cost for businesses. A significant number of companies have already frozen their IT budgets. The war in Ukraine has pushed up oil costs and consequently gas prices; impacting the cost of living and resulting in inflation. The global geo-political scenario is cause for great uncertainty. The Covid pandemic has disrupted the supply chain, adding to the economic uncertainty. The Share and Crypto markets are taking a downturn; so is the valuation of many tech companies. The economic forecasts are unclear and normally effusive pundits are tight lipped.

                                <br></br><br></br>
                                In situations like these, the three normal responses are: fight, flight, or freeze. Most InfoTech companies already seem to be in freeze mode. A situation that’s likely to impact IT budgets, new projects, future recruitment and more. In such a scenario, anxiety, fear and ambiguity are the dominant emotions and sentiment. Yet, history has shown that decisions taken in anxiety or fear, are not in the best interest of anybody.

                            </div>

                            <div className='light_Helvetica line-height-30 font-20 fw-400 pt-5 font-in-mob'>
                                <div className='font-24 bold_Helvetica'> Recession proofing your career</div>

                                <p className='pt-4'>
                                    As an employee, you can either develop a ‘recession mindset’ that operates from scarcity, fear and the need to survive at all costs. Or you can ensure it doesn’t impact your job prospects; by fine tuning the qualities that will accelerate your career.
                                </p>
                                <p>
                                    Google's Project Oxygen researched its own pool of top employees since the company founding in 1998. The results revealed that of the eight qualities considered most important, STEM skills (science, technology, engineering and math) came in eighth. At the top were qualities like good communication and empathy, also called ‘soft skills.’

                                </p>
                                <p>
                                    The ability to code is parity skill. People who can code and collaborate and lead a team or follow someone else when needed, are not just desired, they’re indispensable. Here are five soft skills you need, to recession-proof your career:

                                </p>
                                <ul>
                                    <li className='list-color'>
                                        <p><span className='medium_Helvetica'>Nurture Creativity - </span>
                                            Creativity is much broader than graphic design, content marketing or advertising. Accountants, HR professionals and software engineers can be equally creative. It’s all about the ability to develop new ideas and apply new solutions to resolve existing problems; which will enable businesses discover new opportunities for innovation and growth.

                                        </p>
                                    </li>


                                    <li className='list-color'>
                                        <p><span className='medium_Helvetica'>Cultivate Social Intelligence - </span>
                                        Social intelligence is commonly defined as the ability to get along with others, build relationships and navigate social environments. It’s key to building meaningful and successful relationships with people in an organisation. Some of its key elements include: being verbally and conversationally fluent; possessing effective listening skills; and understanding what makes people tick.

                                        </p>
                                    </li>

                                    <li className='list-color'>
                                        <p><span className='medium_Helvetica'>Enhance Digital Literacy - </span>
                                        Digital literacy is defined as the ability to use digital tools effectively in order to solve problems, be productive, work collaboratively, create content, etc. Research online for the best courses in online security, email etiquette, creating presentations, or email marketing.

                                        </p>
                                    </li>

                                    <li className='list-color'>
                                        <p><span className='medium_Helvetica'>Practice Connect-ability - </span>
                                        Connect-ability is a skill that’s based on three key communication strategies: the authority you exhibit, the warmth you convey, and the energy you radiate and bring out in others. People who embody all these qualities are hard to let go of in hard times. They bring out the best in themselves and motivate others around them. Working intentionally on each of these can differentiate you from the others.

                                        </p>
                                    </li>

                                    <li className='list-color'>
                                        <p><span className='medium_Helvetica'>Demonstrate Leadership - </span>
                                        Leadership in essence is the ability to influence others towards positive outcomes. Getting along with and motivating peers or direct reports, is an increasingly important skill during downturns. Generate solutions to problems that no one else wants to handle or acknowledge. Blue sky thinking is another leadership skill that will take you a long way.

                                        </p>
                                    </li>

                                    <li className='list-color'>
                                        <p><span className='medium_Helvetica'>Courage is Key - </span>
                                        The business world will likely be permanently changed as we emerge from this recession, and there will be a paradigm shift in the perception of the workforce. Your ability to thrive in the emerging reality will depend on how you act now. The skills you build today will enable you to adapt to the uncharted reality that’s emerging. All it takes are three qualities: courage, courage and more courage.

                                        </p>
                                    </li>
                                </ul>

                                I would like to leave you with an inspirational quote from one of my favourite authors:
                                <div className='orangeLine mt-4'>
                                    <p className='italic_Helvetica italic-font quoteColor'>
                                        “Courage is not something that you already have that makes you brave when the tough times start. Courage is what you earn when you’ve been through tough times and you discover they aren’t so tough after all.”
                                        <br /><span className='roman_Helvetica '>~ Malcolm Gladwell, David and Goliath: Underdogs, Misfits, and the Art of Battling Giants</span>
                                    </p>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <BlogSlick name={locName}/>
            <Footer />
        </div>
    )
}

export default Blog1