import React from 'react';
import FooterComponent from "../footer/index";
import { useLocation } from "react-router-dom";
import "./Blog3_Style.scss";
import BlogSlick from "./BlogSlick";
import Blog3Image from "../../assets/images/DisagreeBlogFinalPic.png";
import NavBar from '../nav';

function Blog3() {
    const location = useLocation();
    const locName = location.pathname.split('/')[1];

    return (
        <div>
            <NavBar currLink="resource" />
            <div className='container text-start mt-5 pt-5'>
               
                <div>
                    <img src={`${Blog3Image}`} alt="" className='blogImageWidth' />
                </div>
                <div className='orangeLine'>
                    <p className='italic_Helvetica italic_Para mt-5 mb-5 quoteColor'>“Two people can see the same thing, disagree, and yet both be right. It's not   logical; it's psychological.”<br />
                        <span className='roman_Helvetica'>~ Stephen R. Covey, The 7 Habits of Highly Effective People</span>
                    </p>
                </div>
                <div className='font-20'>
                    <p className='light_Helvetica upperDesc line-height-30'>
                        A great illustration of this maxim, is a story told by a gentleman who served on one of Walt Disney’s original advisory boards, when speaking about how Disney responded to disagreement: Walt Disney would occasionally present some unbelievable, extensive dream he was entertaining. Almost without exception, the members of his board would gulp, blink, and stare back at him in disbelief, resisting even the thought of such a thing. But unless every member resisted the idea, Disney usually didn't pursue it. Why? <br /> <br />

                        Simply because the challenge wasn't big enough to merit his time and creative energy, unless the board was unanimously in disagreement! Disney had the enviable quality of being able to use disagreement in a positive way and tap into its power as a stimulus to produce the kind of creative work that brought in millions to the organisation he founded. <br /> <br />

                        Disagreements are ok, inevitable, normal and a healthy part of interpersonal relationships. The very notion of a ‘conflict-free work environment’ is a myth. Research about conflict at work shows that most people don’t want to disagree, or even find out how to do it. In fact, statements like: “I see it differently,” or “I don’t agree,” are commonly interpreted as being angry, rude, unkind, or relationally abrasive. All of which makes most people very uncomfortable. To be fair, agreeing is usually easier than confronting someone, at least in the short run. <br /> <br />

                        Acute expressions of this can be found on social media, where self-righteous people with very strong opinions on politics or religion, don’t allow any room for discussion. Those who disagree inevitably attract insults, personal belittling and degradation. These are the types who believe that their opinion is superior to everyone else’s and that free speech is theirs alone to enjoy. Not surprisingly, the same attitude is brought into the workplace. <br /> <br />

                        Yet, disagreements when managed well in an atmosphere of mutual respect and an openness to learn from the interaction, have lots of benefits, such as: better work outcomes, opportunities to learn and grow, improved relationships, higher job satisfaction, a more inclusive work environment. <br /> <br />

                    </p>
                </div>

                <div className='mt-3 line-height-30'>
                    <h3 className='bold_Helvetica pb-3'>Effective ways to disagree respectfully</h3>
                    <p className='light_Helvetica upperDesc pb-3 font-20'>Here’s how you can begin the practice of disagreeing respectfully: </p>
                    <ul className='font-20'>
                        <li>
                            <p className='light_Helvetica upperDesc'><span className='medium_Helvetica'>Open your mind -</span>Especially on topics where there are opposing views, the topics are loaded and sensitive, or where people have high stakes. Leave aside predetermined beliefs, opinions or moral judgements. There’s something to learn from everybody.</p>
                        </li>
                        <li >

                            <p className='light_Helvetica upperDesc'><span className='medium_Helvetica'>Listen, listen, listen -</span>Most people are actually listening to themselves and preparing their responses, while believing that they are listening to the other person. Picking up on feelings and emotions being expressed, enables you to shepherd conversations more effectively. Most often, the source of what is being expressed has less to do with the topic at hand and more to do with the emotional baggage of the other person. </p>
                        </li>
                        <li>

                            <p className='light_Helvetica upperDesc'><span className='medium_Helvetica'>Facts matter -</span>Most often there are multiple sides to the story. Ensure you’re not entering the discussion with a pre-set agenda. Sticking to facts enables the discussions to be logical and straightforward, lively and honest. It also enables greater learning about diverse perspectives and ideas. </p>
                        </li>
                        <li>

                            <p className='light_Helvetica upperDesc'><span className='medium_Helvetica'>Emotions can distort -</span>Most people have little control over their emotions; especially when the stakes are high. Flaring tempers, loud volumes, personal criticism and sharp words, scattering the objective of the meeting. Keeping emotions under control, enables you to lead with wisdom and understanding.</p>
                        </li>
                        <li>

                            <p className='light_Helvetica upperDesc'><span className='medium_Helvetica'>Have an exit strategy -</span>Know when to call it a day, especially when opposing viewpoints can’t be resolved via a consensus. Agreeing to disagree on a respectful note, is like an unlocked door with handles on both sides. It empowers both sides to resume the discussion at any time towards consensus and closure.</p>
                        </li>
                    </ul>
                    <p className='mt-4 upperDesc light_Helvetica font-20'>
                        When it comes to disagreeing respectfully, remember that everything can be resolved in communication. Or to put it more succinctly:<br />
                        I would like to leave you with an inspirational quote from one of my favourite authors:
                    </p>
                    <div className='orangeLine font-20'>
                        <p className='italic_Helvetica italic_Para mt-5 mb-5 quoteColor'>
                            “Disagreement is not oppression. Argument is not assault.  Words – even provocative or repugnant ones – are not violence. The answer to speech we do not like is more speech.” <br />
                            <span className='roman_Helvetica'> ~ Douglas Murray, The Madness of Crowds</span>
                        </p>
                    </div>

                </div>

            </div>
            <BlogSlick name={locName} />
            <FooterComponent />
        </div>
    );
}

export default Blog3;
