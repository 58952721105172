import React from 'react';
import NavBar from '../nav';
import "./Blog2_Style.scss";
import { useLocation } from "react-router-dom";
import BlogSlick from "./BlogSlick";
import FooterComponent from "../footer/index";
import Blog2Image from "../../assets/images/CoachBlogFinal2.png";

function Blog2() {
    const location = useLocation();
    const locName = location.pathname.split('/')[1];
    return (
        <div>
            <NavBar currLink="resource" />
            <div className="container text-start mt-3 pt-5">
                <div className='div-with-heading '>
                    <h2 className='blogTwoHeading pb-4'>Why do you need a transition coach, seriously?! </h2>
                    <p className='roman_Helvetica'>By Staff Editor</p>
                    <div className='pt-4 '>
                    <p className='italic-Helvetica blogPara orangeLine quoteColor'>“Change is avalanching upon our heads and most people are grotesquely unprepared to copy with it.”<br />
                        <span className='roman_Helvetica '> ~ Alvin Toffler, Future Shock</span></p>
                </div>
                </div>

                <div className='div-with-text'>

                    <div>
                        <p className='blogPara light_Helvetica text-start line-height-30'>
                            Career change statistics tell us that the average person will change careers 10 to 15 times during their working years. More importantly 30% of the current workforce will change careers or jobs every year (12 months). At this rate of flux, it’s virtually impossible for individuals to know in advance, be prepared for, or cope up with the specificities of the new role, its responsibilities, demands, pitfalls and evolving challenges.  Here’s where a transition coach comes in. But before we delve into the tremendous impact a transition coach, or transition coaching can make, it’s important to distinguish between change and transition. <br /> <br />

                            People constantly confuse themselves and others, by using these two words interchangeably. But they’re not the same. According to William Bridges, author of Transitions: Making sense of Life’s Changes, “Change is your move to a new city, or your shift to a new job. It is the birth of your new baby or the death of your father. It is… the replacement of your manager by a new one, or it is the acquisition that your company just made. In other words, change is situational. Transition, on the other hand, is psychological. It is not those events, but rather the inner orientation and self-definition that you have to go through in order to incorporate any of those changes into your life. Without a transition, a change is just a rearrangement of the furniture.” <br /> <br />

                            There’s much talk about change, very little about transition in the sense we have defined it above. Yet, transition is often the source of an employees’ troubles. Transition of any sort can be overwhelming for employees and opting for support in these times in form of a Career Transition Coach can be a great deal of assistance. <br /> <br />
                        </p>
                        <div>
                            <img src={`${Blog2Image}`} alt="" className='blogImageWidth pb-5' />
                        </div>
                        <div>
                            <p className='blogPara light_Helvetica text-start line-height-30'>
                                <h3 className='boldHelvetica-font coachingHeading'>Transition Coaching – the inherent benefits: <br /><br /></h3>

                                Transition coaching is a creative process of discovery, action and growth; where the Transition Coach helps clarify your intentions and sets you on the path to finding true career alignment and fulfillment. <br /> <br />

                                The Transition Coaching mandate is to help people understand the need for transition; get clarity on the areas of change that this calls for; envision the benefits from the transition and enable the person to stay engaged, productive and content. <br /><br />
                            </p>
                            <p className='blogPara light_Helvetica text-start line-height-30'>
                                <h4 className='roman_Helvetica coachingHeading mb-4'>Here’s how Transition Coaching benefits individuals and organisations:</h4>
                                <ul>
                                    <li>
                                        Provides a supportive, neutral and productive relational environment, that enables you to maximize your potential and embark on a successful career journey. 
                                    </li>
                                    <br />
                                    <li>
                                        Helps overcome stress, evaluate opportunities, overcome procrastination, creating a vision-mission and value for self, developing emotional quotient and managing the transition with a plan of action. 
                                    </li><br />
                                    <li>
                                        Maps existing capabilities and competencies to what is expected from the new role 
                                    </li><br />
                                    <li>
                                        Analyzing gaps, if any, enables the discovery of an individual’s potential to cover those gaps. 
                                    </li><br />
                                    <li>
                                        Co-creates a time-bound action plan to navigate through the transition. 
                                    </li><br />
                                    <li>
                                        Enables the reshaping of the individual’s thought processes regarding their capabilities in the workplace and learn techniques that will help them believe in themselves, enhance self-confidence and flourish in their new role.
                                    </li><br />

                                </ul>

                                When it’s all been said and done, there’s just one answer to people who’re still wondering why they need a (transition) coach: <br />
                            </p>
                        </div>


                    </div>
                    <div className='pb-4 pt-5'>
                        <p className='italic-Helvetica blogPara orangeLine quoteColor'>“Who, exactly, seeks out a coach? If you ask a coach the answer is usually the same: Winners who want even more out of life.”
                            <br /><span className='roman_Helvetica '>~ Abigail Pickus</span>
                        </p>
                    </div>
                </div>

            </div>
            <BlogSlick name={locName}/>
            <FooterComponent />
        </div>
    );
}

export default Blog2;
