
import React, { Component } from "react";
import Slider from "react-slick";
import RecessionPic from "../../assets/images/RecessionUpdated.png";
import BlogCoach from "../../assets/images/CoachUpdated.png";
import BlogDisagree from "../../assets/images/DisagreeUpdated.png";
import BlogBirds from "../../assets/images/AdaptiveUpdated.png";
import BlogTalk from "../../assets/images/EventsUpdated.png";
import BlogAI from "../../assets/images/AibackgroundUpdated.png";
import "./BlogSlick.scss";

export default class MultipleItems extends Component {
  
  componentDidMount(){
    function consFunc(){
      const b = document.getElementsByClassName('slick-next')
     b[0].click();
   }

   consFunc();
  }
  render() {
    const settings = {
      dots: false,
      // infinite: true,
      // speed: 500,
      autoplay: true,
      autoplaySpeed: 2500,
      slidesToShow: 3,
      slidesToScroll: 1,

      responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      }]
    };
    
   
    
    return (
      <div className="pt-4 ps-5 pe-5 mt-5 slickBg">
        
        <h2 className="bold_Helvetica text-center slickTitle mb-4"> {( this.props && (this.props.name).includes('blog') )? 'Other Blogs' : 'Resources'} </h2>
        <Slider {...settings} >

          
          <div className="px-2 " id="blog4" style={{ display: " this.props.name == 'blog4'? 'none': '' "}}>

            <div>
              <a href="/blog4"><img src={`${BlogBirds}`} alt=" "  /></a></div>
            <a href="/blog4" className="anchorClass">
              <p className="roman_Helvetica mt-3" style={{ fontSize: "16px" }}>Adaptive Leadership in the New<br/>Paradigm of Work </p>
            </a>
          </div>
          <div className="px-2">
            <div><a href="/blog5"><img src={`${BlogTalk}`} alt=" "  /></a></div>
            <a href="/blog5" className="anchorClass">
              <p className="roman_Helvetica mt-3" style={{ fontSize: "16px" }}>Jobs are journeys, not events! </p>
            </a>
          </div>
          <div className="px-2">
            <div><a href="/blog1" ><img src={`${RecessionPic}`} alt=""  /></a></div>
            <a href="/blog1" className="anchorClass">
              <p className="roman_Helvetica mt-3" style={{ fontSize: "16px" }}>Why waste a good recession?</p>
            </a>
          </div>
          <div className="px-2">
            <div><a href="/blog2"><img src={`${BlogCoach}`} alt=" "  /></a></div>
            <a href="/blog2" className="anchorClass">
              <p className="roman_Helvetica mt-3" style={{ fontSize: "16px" }}>Why do you need a<br/>transition coach, seriously?! </p>
            </a>
          </div>
          <div className="px-2">
            <div><a href="/blog3"><img src={`${BlogDisagree}`} alt=" "  /></a></div>
            <a href="/blog3" className="anchorClass">
              <p className="roman_Helvetica mt-3" style={{ fontSize: "16px" }}>Disagreeing is good.<br/>Doing it respectfully is better. </p>
            </a>
          </div>
          <div className="px-2">
            {/* need to change */}
            <div><a href="/blog6"><img src={`${BlogAI}`} alt=" "  /></a></div>
            <a href="/blog6" className="anchorClass">
              <p className="roman_Helvetica mt-3" style={{ fontSize: "16px" }}>Humanoid Recruiters</p>
            </a>
          </div>
        </Slider>
      </div>
    );
  }
}


