import React from "react";
import "./style.scss";
import HowWeDoIt from '../howWeDoIt/index';
import Team from '../ourTeam/index';
import Walk from "../../assets/images/AboutUsFinal.png";
import DeepKnowledge from "../../assets/images/DeepKnowledge.png";
import IndustryNetwork from "../../assets/images/IndustryNetwork.png";
import Scientific from "../../assets/images/ScientificResearch.png";
import Strong from "../../assets/images/StrongProcess.png";
import Credentials from "../../assets/images/Credentials.png";

function AboutUs() {
  return (
    <div id="aboutUs" className="px-3 pt-5" >
      <div className="row  align-items-center pt-5 pb-3">
        <h1 className="roman_Helvetica text-center pt-5 pb-5">About{" "}<span className="bold_Helvetica">Us</span></h1>
        <div className="col-12 col-md-6 text-center">
          <img src={`${Walk}`} alt="" className="imgAbtU" />
        </div>
        <div className="pt-3 pb-3 aboutPara col-12 col-md-6 ">
          <h2 className="head-font centerText">
            When Our Candidates excel,
            <br />
            <span className="text-orange bold_Helvetica">we succeed.</span>
          </h2>
          <p className="pt-3 pb-1 centerText">
            The digital and technology talent marketplace has been our playing
            field for over two decades. We have evolved with the technology
            industry and have adeptly kept pace with its dynamics. Our expertise
            has grown steadily to include the broad spectrum of organisations --
            from well-established technology companies to just-born cloud-based
            enterprises.
            <br/><br/>
            Our vast experience combines with a meticulous approach to ensure
            that we offer only best-fit resources. The carefully selected
            candidates embody our steadfast values. And when they succeed, it
            further establishes our credentials as trusted leadership hiring
            consultants. Not surprisingly our clients keep coming back to us
            with their executive search requirements, and we deliver - every
            single time.
            <br /><br />
            Trust, quality, speed, integrity, and timely delivery are our
            defining values as we join hands with clients to source the right
            leadership talent. We are aided by our indigenously developed
            methodologies that turn the art of cherry-picking high calibre
            talent, into a science.
          </p>
        </div>
      </div>
      <div className="text-center justify-content-center ">
        <div className="triangleBg">
          <div>
            <h2 className="pb-5 differHeading">Our{" "}<span className="fw-bold">Differentiators</span></h2>
            <h4 className="head-Decor">
              We bring great <span className="text-orange bold_Helvetica">value </span>to
              client <span className="text-orange bold_Helvetica">partnerships </span>with the{" "}
              <span className="text-orange bold_Helvetica">advantages</span> that differentiate
              us.
            </h4>
          </div>
          <div className="row justify-content-center">
            <div className="card text-center m-4" style={{ width: "332px", height: "224px" }}>
              <div className="card-body">
                <img src={`${DeepKnowledge}`} alt="" className="pb-3" />
                <p className="card-title text-orange card-head">Deep Knowledge</p>
                <p className="card-text romanHelvetica-font card-desc">
                of the digital and technology marketplace combined with thorough business understanding.
                </p>
              </div>
            </div>

            <div className="card text-center m-4" style={{ width: "332px", height: "224px" }}>
              <div className="card-body">
                <img src={`${IndustryNetwork}`} alt="" className="pb-3" />
                <p className="card-title text-orange card-head">
                  Reliable Industry Network
                </p>
                <p className="card-text romanHelvetica-font card-desc">
                that allows us to assess and reach out to the best candidates.
                </p>
              </div>
            </div>
            <div className="card text-center m-4" style={{ width: "332px", height: "224px" }}>
              <div className="card-body">
                <img src={`${Scientific}`} alt="" className="pb-3" />
                <p className="card-title text-orange card-head">Scientific approach</p>
                <p className="card-text romanHelvetica-font card-desc">
                that enables us to deliver excellence time and again.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="card text-center m-4" style={{ width: "332px", height: "224px" }}>
              <div className="card-body">
                <img src={`${Strong}`} alt="" className="pb-3" />
                <p className="card-title text-orange card-head">Strong processes</p>
                <p className="card-text romanHelvetica-font card-desc">
                to provide thoroughly vetted and evaluated candidates.
                </p>
              </div>
            </div>
            <div className="card text-center m-4" style={{ width: "332px", height: "224px" }}>
              <div className="card-body">
                <img src={`${Credentials}`} alt="" className="pb-3" />
                <p className="card-title text-orange card-head">Impeccable credentials</p>
                <p className="card-text romanHelvetica-font card-desc">
                as a trusted name in executive search with a proven track record in leadership placements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HowWeDoIt />
      <Team />
    </div>
  );
}

export default AboutUs;
