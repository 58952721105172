import Ust from "../../assets/images/UstPartner.png";
import Adrenalin from "../../assets/images/AdrenalinPartner.png";
import Tiger from "../../assets/images/TigerPartner.png";
import Guardian from "../../assets/images/GuardianPartner.png";
import Mphasis from "../../assets/images/MphasisPartner.png";
import Sterling from "../../assets/images/SterlingPartner.png";
import Cams from "../../assets/images/CamsPartner.png";
import TvsCredit from "../../assets/images/TvsCreditPartner.png";
import Lnt from "../../assets/images/LntPartner.png";
import Larsen from "../../assets/images/LarsenPartner.png";
import Murugappa from "../../assets/images/MurugappaPartner.png";
import Viteos from "../../assets/images/VietosPartner.png";
import Tvs from "../../assets/images/TvsPartner.png";
import Redington from "../../assets/images/RedingtonPartner.png";
import Prodapt from "../../assets/images/ProdaptPartner.png";
import Persistent from "../../assets/images/PersistentPartner.png";
import Mindtree from "../../assets/images/MindtreePartner.png";
import Wipro from "../../assets/images/WiproPartner.png";
import Nielsen from "../../assets/images/NielselPartner.png";
import Coforge from "../../assets/images/CoforgePartner.png";
import Standard from "../../assets/images/StandardPartner.png";
import Coats from '../../assets/images/Coats.png';
import Esab from '../../assets/images/ESAB.png';
import CaplinPoint from '../../assets/images/Caplin point.png';
import Coromandel from '../../assets/images/coromandel.png';
import Parry from '../../assets/images/Parry.png';
import Rane from '../../assets/images/RaneLogo.png';
import Tube from '../../assets/images/Tube Investments.png';

const partner =[
    {
        "id" : 1,
        "partner" : Ust
    },
    {
        "id" : 2,
        "partner" : Guardian
    },
    {
        "id" : 3,
        "partner" :  Cams
    },
    {
        "id" : 4,
        "partner" : TvsCredit
    },
    {
        "id" : 5,
        "partner" : Mphasis
    },
    {
        "id" : 6,
        "partner" : Larsen
    },
    {
        "id" : 7,
        "partner" : Tiger
    },
    {
        "id" : 8,
        "partner" : Lnt
    },
    {
        "id" : 9,
        "partner" : Adrenalin
    },
    {
        "id" : 10,
        "partner" : Mindtree
    },
    {
        "id" : 11,
        "partner" : Sterling
    },
    {
        "id" : 12,
        "partner" : Tvs
    },
    {
        "id" : 13,
        "partner" :  Viteos
    },
    {
        "id" : 14,
        "partner" :  CaplinPoint
    },
    {
        "id" : 15,
        "partner" : Prodapt
    },
    {
        "id" : 16,
        "partner" : Coats
    },
    {
        "id" : 17,
        "partner" : Persistent 
    },
    {
        "id" : 18,
        "partner" :  Tube
    },
    {
        "id" : 19,
        "partner" : Nielsen
    },
    {
        "id" : 20,
        "partner" : Coforge
    },
    {
        "id":21,
        "partner": Parry
    },
    {
        "id":22,
        "partner": Murugappa
    },
    {
        "id":23,
        "partner":Esab
    },
    {
        "id":24,
        "partner":Redington
    },
    {
        "id":25,
        "partner":Coromandel
    },
    {
        "id":26,
        "partner": Standard
    },
    {
        "id":27,
        "partner":Rane
    },
    {
        "id":28,
        "partner":Wipro
    }
]
export default partner;