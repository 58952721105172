import Cooper from "../../assets/images/CooperIcon.png";
import Murugappa from "../../assets/images/MurugappaRed.png";
import Tvs from "../../assets/images/Tvs.png";
import Tiger from "../../assets/images/TigerPartner.png";

 const element = [
    {
        "id" : 1,
        "headerImg":Tiger,
        "content": "Saaki Argus & Averil have been our go-to partners for middle and senior leadership roles. They have truly listened to our requirements of role and culture fit and have recommended candidates that are a close match.They have engaged candidates through our tough hiring process.Their customer commitment has been exemplary. Thank you, Saaki Argus and Averil.",
        "authorName":"Sanjay Sundaram",
        "authorSignature":"Vice President"
    },
    {
        "id" : 2,
        "headerImg": Cooper,
        "content": "SAA is a trusted advisor and not just a delivery partner. Sathya and team have helped Mr. Cooper India with strategic hiring inputs ever as times when it isn’t necessarily in their best financial interest. Thank you team Saaki Argus & Averil Consulting for all you do!",
        "authorName":"Arati Mohanram",
        "authorSignature":"India HR Head"
    },
    {
        "id" : 3,
        "headerImg":Murugappa,
        "content": "It has been a pleasure to work with the team at Saaki Argus on our leadership hiring initiatives. Because they bring a valuable mix of business understanding, insights into the success factors for specific roles and an honest view of cultural fitment, they make the process efficient and effective. Plus, they are quick to respond and engage relentlessly with company and candidate till closure. Sathya herself sets a great example with her unending enthusiasm and transparent and professional approach to search. We wish the team continued success.",
        "authorName":"Shyam C Raman",
        "authorSignature":"Executive Vice President-HR"
    },
    
    {
        "id" : 4,
        "headerImg":Tvs,
        "content": "It has been a pleasure to work with Saaki Argus & Averil consulting on some of our critical positions. They have a good track record in filling several challenging positions over the last 11 years with TVS Credit services. I look forward to continue this relationship in our future journey too. I wish them all success.",
        "authorName":"Jayashubha k",
        "authorSignature":"Chief People Officer"
    },
    
]

export default element;