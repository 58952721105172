
import React from "react";
import './style.scss';
import Job from "../../assets/images/JobsFinalImg.png";

function Jobs() {
  return (
    <div id="jobs" className="pt-5">
      <div className="text-center pt-5 pb-2 bg-grey">
        <h3 className="jobs-head">Jobs</h3>
      </div>
      <div className="text-center bg-grey pb-5 px-1">
        <img className="jobs-img " src={`${Job}`} alt="" />
        <p className="pt-3 pb-3 m-2 jobs-text">
          Explore New opportunities.
        </p>
        <div>
          <button className="btn button-color">
            {" "}
            <a
              href="https://jobs.saaconsulting.co.in/"
              target="_blank"
              rel="noreferrer"
              className="text-white text-decoration-none txt-size"
            >
              Find Jobs{" "}
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Jobs;
