import React from 'react';
import FooterComponent from "../footer/index";
import "./Blog5_Style.scss";
import BlogSlick from "./BlogSlick";
import { useLocation } from "react-router-dom";
import NavBar from '../nav';

import Blog5Img from "../../assets/images/EventsBlogFinalPic.png";


function Blog5() {
    const location = useLocation();
    const locName = location.pathname.split('/')[1];
    return (
        <div>
            <NavBar currLink="resource" />
            <div className="container mt-5 pt-5">
                
                <div>
                    <h2 className='bold_Helvetica BlogHeading pb-4'>Jobs are journeys, not events! </h2>
                    <p className='roman_Helvetica pb-3'>
                        By Staff Editor
                    </p>
                </div>
                <div className='orangeLine'>
                    <p className='italic_Helvetica font-20 mb-4 quoteColor'>
                        “If you say your job is something you don’t plan on doing forever, then why are you doing it right now?” <br /><span className='roman_Helvetica'>~ Simon Sinek</span>
                    </p>
                </div>
                <div>
                    <p className='light_Helvetica font-20 pb-3'>
                        There are many ways to look at this Simon Sinek quote. Here are three stories that broadly illustrate the reality of the career dilemma that thousands of job-seekers face. The conclusions to each story, provides perspectives that job-seekers would do well to consider.
                    </p>
                </div>
                <div>
                    <img src={`${Blog5Img}`} alt="" className='Blog5Image pt-3 pb-5' />
                </div>
                <div>
                    <h2 className='Blog5Heading bold_Helvetica pb-3 pt-3'>Pratima’s Story</h2>
                    <p className='light_Helvetica font-20'>Pratima (not her real name), a bright young girl from a Mumbai slum, completed high school with the help of an NGO. Her grades enabled her to get admission in a popular Mumbai college, from which she graduated with a degree in Arts. Pratima then pursued a Masters in English Lit. from another good college and was eagerly looking forward to entering publishing as a career.</p>
                    <p className='light_Helvetica font-20'>Considering the challenges that the publishing business is currently facing, her mentors advised her to look elsewhere. Meanwhile the college from which Pratima completed her Masters, offered her a position teaching English Lit., at a reasonably good salary. It would seem like life was made for Pratima; but there was one tiny hitch. Pratima didn’t quite fancy teaching and was keen on pursuing a career where her love for the English language could express itself creatively. Pratima’s conundrum still hasn’t been resolved.</p> <br />
                    <h3 className='bold_Helvetica takeAway mb-3'>Takeaways from Pratima’s story</h3>
                    <ul>
                        <li className='light_Helvetica font-20'>
                            Circumstances may not always allow you to start off in the job your education has qualified you for.
                        </li>
                        <li className='light_Helvetica font-20'>
                            The job you reluctantly choose, may be one you turn into a career.
                        </li>
                        <li className='light_Helvetica font-20'>
                            Never lose sight of your passion, but put a cap on how long you will wait for your dream job.
                        </li>
                    </ul>
                </div>
                
                <div className='pt-4'>
                    <h2 className='bold_Helvetica Blog5Heading mt-5 mb-4'>Shraddha’s Story</h2>
                    <p className='light_Helvetica font-20'>
                        Most of us are fans of the stand-up comic and content creator Shraddha Jain, a.k.a. Aiyyo Shraddha. In a recent interview with ET Brand Equity, Shraddha recounted her journey to fame. Hailing from a South Indian middle class family, Shraddha’s mother was a Govt. school teacher and her father was an accountant.
                    </p>
                    <p className='light_Helvetica font-20'>
                        Shraddha’s academic record was excellent. She got into engineering and was campus placed. There was nothing going off track. Her first job was with iGate Global Solutions. Thereafter she joined a bigger company and was sent to the US. Everything looked hunky dory.
                    </p>
                    <p className='light_Helvetica font-20'>
                        But things came to a head when Shraddha realised she wasn’t as good as her colleagues at what she was doing. That’s when it occurred to Shraddha that maybe she should become a Radio Jockey. After a few years of doing radio, she posted a humorous Facebook video on Karnataka’s Averakaayi Parishe festival, which went viral. That’s when her career as a comedian took off.
                    </p>
                    <p className='italic_Helvetica font-20 mt-4'>Shraddha’s YouTube channel today has 240,000 subscribers; while her Facebook page has 187,893 likes.</p><br/>
                    <h3 className='bold_Helvetica takeAway mt-3 mb-3'>Takeaways from Shraddha’s Story</h3>
                    <ul>
                        <li className='light_Helvetica font-20'>
                            Transition jobs and careers are a reality.
                        </li>
                        <li className='light_Helvetica font-20'>
                            Nobody has definitive answers on the direction their careers may take.
                        </li>
                        <li className='light_Helvetica font-20'>
                            Risking a mid-career leap into something totally different, often allows you the freedom to come back to what you left; as you have sufficient skills
                            and experience to fall back on.
                        </li>
                    </ul>
                </div>
                
                <div className='pt-4'>
                    <h2 className='bold_Helvetica Blog5Heading mt-5 mb-4'>Curtis’ Story</h2>
                    <p className='light_Helvetica font-20'>
                    Some 15 miles from central Philadelphia, stands the Cornwells Heights train station. It has a large parking lot, and on most weekdays 100s of commuters park their cars there before taking the 20-min train ride to downtown Philadelphia, a.k.a. Centre City.
                    </p>
                    <p className='light_Helvetica font-20'>
                    Since the parking lot is some distance away from the platforms, the Southeastern Pennsylvania Transportation Authority (SEPTA) runs a shuttle bus between the station and the parking lot. The drivers who operate these buses have an excruciatingly boring job. Hour after hour, they wait for the trains to arrive and then drive the passengers from the station to the parking lot and then again wait or the next train. As they sit slumped in their seats, most drivers look as though they would do anything to escape the tedium. Curtis Perrin is an exception.
                    </p>
                    <p className='light_Helvetica font-20'>
                    Perrin’s demeanour and behaviour reflect anything but boredom. Each time a passenger enters his bus, he calls out in his gritty, hearty voice: “Hey there, buddy! How are you today?” If an elderly woman enters, Curtis says, “Hello, young lady!” Even more remarkable than his wide smile and booming voice is his memory: While other drivers drive along a preassigned route - stopping at Parking Lots A, B, C, and so on, Curtis usually drives each passenger to his or her own car. As the exhausted passengers alight from the bus, he calls out again, “Have a great evening, buddy!” Or “Happy Friday, sir. Have a great weekend.” Curtis’s way has endeared him to countless passengers.
                    </p>
                    
                    <h3 className='bold_Helvetica takeAway mt-5 mb-4'>Takeaways from Curtis’ Story</h3>
                    <ul>
                        <li className='light_Helvetica font-20'>
                        In any job, it’s all about who you are and what you bring in, not what you do or take away.
                        </li>
                        <li className='light_Helvetica font-20'>
                        There’s always something you can find in every job, which when expressed will make you stand out.
                        </li>
                        <li className='light_Helvetica font-20'>
                        You have the choice of owning your work and making a real difference to it, rather than merely trading your time and labour for money.
                        </li>
                    </ul>
                    <p className='light_Helvetica font-20 pt-3'>Let me close with a few words of encouragement from Simon Sinek, excerpted from an interview he gave to Monster, about his book: ‘Together is Better’:</p>
                </div>
                
                <div className='orangeLine mt-5 mb-5'>
                <p className='italic_Helvetica font-20 quoteColor'>
                “A job is not an event, our career is not an event; it's a journey. The most important thing is to remember to ask for help and to accept it when it’s offered. People who are willing to say, “I don’t know what to do, I need help,” are the people who actually do better in life because amazingly, we’re surrounded by people who want to help us…” 
                </p>
                </div>
                
            </div>
            
            <BlogSlick name={locName}/>
            <FooterComponent/>
        
        </div>
        
    );
}

export default Blog5;
