import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import partner from "../../shared/Static/Partners";
import "./style.scss";

const slickSlide = partner.map((img,index)=>{
  return <div key={index}>
    <div>
            <img src={img.partner} alt="" className="partners-Size"/>
          </div>
  </div>

})

export default class MultipleItems extends Component {
  
  render() {
    
    let settings_3 = {
      dots: false,
      // autoplay: true,
      // arrows: false,
      autoplaySpeed: 3000,
      slidesToShow: 4,

      responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      }]
    };

    return (
      <div className="container mb-5">
        <div className="d-flex justify-content-center p-3 mb-5">
          <h2 className="partners-head">
            Our <span className="fw-bold">Partners</span>
          </h2>
        </div>
        <div className="px-5">
        <Slider className ="custom-slick" {...settings_3}>
         {slickSlide}
        </Slider>
        </div>
      </div>
    );
  }
}
